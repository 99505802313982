import { replaceTemplateValues } from '@/utils';
import router from '@/router';
import api from '@/api/creator/creator';
import * as helper from '@/helpers/creator.helper';
import i18n from '../../i18n';

import { caseStatuses } from '@/enums/cases.enums';
import { creator } from '@/enums/creator.enums';

// States
const state = {
    dialogState: creator.CLOSED,
    creatorFormOptions: [],
    currentForm: null,
    currentFormData: null,
    dirty: false,
    listenForChange: false,
};

// Getter functions
const getters = {};

// Actions
const actions = {
    async getCreatorFormOptions({ commit }) {
        try {
            const response = await api.getCreatorFormOptions();
            const { data } = response;
            commit('SET_CREATOR_FORM_OPTIONS', data);
        } catch (error) {
            console.log(error);
        }
    },

    setDirty({ commit }, payload) {
        commit('SET_DIRTY', payload);
    },

    setListenForChange({ commit }, payload) {
        commit('SET_LISTEN_FOR_CHANGE', payload);
    },

    // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <This needs to be refactored>
    async postForm({ commit, dispatch, state, rootState }, valid) {
        try {
            if (!valid) {
                this._vm.$toasted.show(i18n.t('creator.notValid'), {
                    icon: 'mdi-alert-circle-outline',
                    type: 'error',
                });
                return;
            }

            const { currentFormData } = state;
            const { userId } = rootState.Auth.userObject;

            const signatureSelect = currentFormData.form.inputs.find((input) => input.type === 'SIGNATURE_SELECT');
            const tiptap = currentFormData.form.inputs.find((input) => input.type === 'TIP_TAP');

            if (signatureSelect) {
                const signatureId = signatureSelect.value.value;
                const signatureTemplate = rootState.Admin.signatureTemplates.find(
                    (template) => template.ID === signatureId,
                );

                tiptap.value.signature = signatureTemplate
                    ? {
                          Content: replaceTemplateValues(signatureTemplate.Content, {
                              client: {},
                              agent: rootState.Auth.userObject || {},
                              sender: rootState.Cases.systemEmails[0] || '',
                          }),
                      }
                    : { Content: '' };
            }

            const response = await api.postForm({ ...currentFormData, userId });

            if (response.status === 200) {
                commit('SET_DIALOG_STATE', creator.CLOSED);
                this._vm.$toasted.show(i18n.t(`creator.${response.data.code || 'success'}`), {
                    icon: 'mdi-content-save',
                    type: 'success',
                });

                const caseId = response?.data?.caseId;
                const parentCaseId = response?.data?.parentCaseId;

                // Route to the case if caseId is present - and its not a sub case
                if (caseId && !parentCaseId) {
                    await dispatch('Cases/setSelectedCaseId', caseId, { root: true });
                    const url = `/cases/${caseId}`;
                    router.push(url);
                    const element = document.querySelector('#scroll-container');
                    element.scrollTop = element.scrollHeight;

                    helper.executeCall(response?.data);
                }

                if (parentCaseId) {
                    this._vm.$toasted.show(i18n.t('cases.subCaseCreated'), {
                        theme: 'toasted-information',
                        position: 'bottom-center',
                        duration: 30_000,
                        icon: 'mdi-arrow-right-thin',
                        action: {
                            text: i18n.t('cases.goToSubCase'),
                            onClick: async (_, toastObject) => {
                                toastObject.goAway(0);
                                await dispatch('Cases/setSelectedCaseId', caseId, { root: true });
                                const url = `/cases/${caseId}`;
                                router.push(url);
                                const element = document.querySelector('#scroll-container');
                                element.scrollTop = element.scrollHeight;
                            },
                        },
                    });
                }

                if (currentFormData?.form?.return) {
                    await dispatch('openForm', {
                        id: currentFormData.form.return,
                        keys: currentFormData.form.returnKeys,
                    });
                }
            }
        } catch (error) {
            console.error('Error in postForm', error);
            // Duplicate form
            if (error.response.data.status === 409) {
                this._vm.$toasted.show(i18n.t('creator.duplicate'), {
                    icon: 'mdi-alert-circle-outline',
                    type: 'error',
                });
                return;
            }

            if (error.response.status === 400) {
                if (!error.response.data.paths) {
                    const message = error.response.data?.message
                        ? `Error: ${error.response.data.message}`
                        : i18n.t('creator.error');
                    this._vm.$toasted.show(message, {
                        icon: 'mdi-alert-circle-outline',
                        type: 'error',
                    });
                    return;
                }

                const { paths } = error.response.data;

                const customErrorMessage = paths.map((path) => i18n.t(`creator.${path.toLowerCase()}`)).join(', ');

                this._vm.$toasted.show(i18n.t('creator.validationFailedFor') + customErrorMessage, {
                    icon: 'mdi-alert-circle-outline',
                    type: 'error',
                });

                return;
            }

            this._vm.$toasted.show(i18n.t('creator.error'), {
                icon: 'mdi-alert-circle-outline',
                type: 'error',
            });
        }
    },

    async setCurrentForm({ state, rootState, commit, dispatch }, option) {
        try {
            if (option === null) {
                commit('SET_CURRENT_FORM', null);
                commit('SET_CURRENT_FORM_DATA', null);
                return;
            }

            // This is to set default status closed when sending email or sms since it is outgoing communication
            let [{ id, keys }] = option;

            if (id === 2 || id === 7) {
                keys = { status: { value: caseStatuses.CLOSED } };
            }

            const caseId = rootState.Cases.selectedCase?.case?.caseId || null;

            const response = await api.getFormByOption(id, caseId);

            const { data } = response;

            const updatedData = { ...data };

            if (keys && typeof keys === 'object') {
                const updatedInputs = updatedData.form.inputs.map((input) => {
                    if (keys.hasOwnProperty(input.bind)) {
                        const key = keys[input.bind];
                        for (const subKey in key) {
                            input[subKey] = key[subKey];
                        }
                    }

                    return input;
                });

                updatedData.form.inputs = updatedInputs;
                updatedData.form.returnKeys = keys.returnKeys;
                updatedData.form.return = keys.return;
                updatedData.form.keys = keys;
            }

            await dispatch('getCreatorFormOptions');

            commit('SET_CURRENT_FORM_DATA', updatedData);

            commit(
                'SET_CURRENT_FORM',
                state.creatorFormOptions.find((form) => form.id === id),
            );
        } catch (error) {
            console.log(error);
        }
    },

    toggleDialog({ commit, state }) {
        if (state.dialogState === creator.OPEN) {
            commit('SET_DIALOG_STATE', creator.CLOSED);
            return;
        }
        commit('SET_DIALOG_STATE', creator.OPEN);
    },

    openDialog({ commit }) {
        commit('SET_DIALOG_STATE', creator.OPEN);
    },

    async openForm({ dispatch, commit }, { id, keys }) {
        commit('SET_DIRTY', false);
        commit('SET_LISTEN_FOR_CHANGE', false);
        // Sets the values of the form inputs by the keys
        await dispatch('setCurrentForm', [{ id, keys }]);

        dispatch('openDialog');

        setTimeout(() => {
            commit('SET_LISTEN_FOR_CHANGE', true);
        }, 2000);
    },

    async handleChange({ commit, state }, payload) {
        const templateSelects = [
            'TEMPLATE_SELECT',
            'SMS_TEMPLATE_SELECT',
            'EMAIL_TEMPLATE_SELECT',
            'CONFIRMATION_TEMPLATE_SELECT',
        ];

        if (templateSelects.includes(payload.item.type)) {
            const selectedTemplate = payload.value;

            const { content } = selectedTemplate;

            const tipTap = state.currentFormData?.form?.inputs.find(
                (input) => input.type === 'TIP_TAP' || input.type === 'SMS_TIP_TAP',
            );

            let oldContent = '';

            if (tipTap) {
                oldContent = tipTap.value.content;
            }

            const { attachments } = selectedTemplate;
            const formattedAttachments = attachments.map((attachment) => ({
                file: attachment,
                type: 'reference',
            }));
            // Need to first clear the existing content and then set the new content to make tiptap update
            await commit('SET_CURRENT_FORM_DATA_TIP_TAP', { content: '', attachments: [] });
            await commit('SET_CURRENT_FORM_DATA_TIP_TAP', {
                content: oldContent + content,
                attachments: formattedAttachments,
            });

            return;
        }

        const currentFormData = structuredClone(state.currentFormData);

        if (payload.item.type === 'TIP_TAP') {
            // Check if there is a sms component
            const smsComponent = currentFormData.form.inputs.find((input) => input.type === 'SMS_PREVIEW');
            if (smsComponent) {
                smsComponent.value = payload.value;
            }
        }

        const component = currentFormData.form.inputs.find((input) => input.id === payload.item.id);

        component.value = payload.value;

        commit('SET_CURRENT_FORM_DATA', currentFormData);
    },
};
// Mutations
const mutations = {
    SET_DIRTY(state, payload) {
        state.dirty = payload;
    },
    SET_LISTEN_FOR_CHANGE(state, payload) {
        state.listenForChange = payload;
    },
    SET_DIALOG_STATE(state, payload) {
        state.dialogState = payload;
    },

    SET_CREATOR_FORM_OPTIONS(state, payload) {
        state.creatorFormOptions = payload;
    },

    SET_CURRENT_FORM(state, payload) {
        state.currentForm = payload;
    },

    SET_CURRENT_FORM_DATA(state, payload) {
        state.currentFormData = payload;
    },

    SET_CURRENT_FORM_DATA_TIP_TAP(state, payload) {
        const currentFormData = structuredClone(state.currentFormData);

        const tiptapTypes = new Set(['TIP_TAP', 'SMS_TIP_TAP']);
        const tiptap = currentFormData.form.inputs.find((input) => tiptapTypes.has(input.type));

        tiptap.existingContent = payload.content;
        tiptap.value = payload;
        tiptap.files = payload.attachments;
        state.currentFormData = currentFormData;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
