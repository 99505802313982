<template>
    <div v-if="isVergicChat" class="comments incoming vergic-chat">
        <div class="d-flex align-center">
            <InternalType :absolute="false" :item="{ internalType: 'chat' }" />
            <div class="ml-3" v-html="item.comment"></div>
            <span class="ml-2 comment-date comment-date-text">{{ getDay(item.dateTime) }}</span>
        </div>
    </div>
    <div v-else class="comments" :style="chatSize">
        <!-- Header -->
        <header>
            <div class="text-h6">{{ item.from || $t('global.unknown') }}</div>
            <div class="actions">
                <div v-if="verified">
                    <img class="bankid-verified" src="./bankid_verified.svg" />
                </div>
                <button
                    v-else-if="isIntegrationActive('BANKID')"
                    :disabled="verificationRequested || disabled"
                    @click="intitiateBankIdVerification"
                    class="bank-verication-btn"
                >
                    <span>
                        <img src="./BankID_logo_black.svg" />
                        {{ $t('chat.startBankIdVerification') }}
                    </span>
                </button>
                <v-btn icon @click="toggleSize()">
                    <v-icon size="20">{{ expandIcon }}</v-icon>
                </v-btn>
                <v-btn :disabled="isReadOnly || chatState === chatStates.CLOSED" icon @click="closeChat()">
                    <v-icon size="20">mdi-close</v-icon>
                </v-btn>
            </div>
        </header>

        <!-- Content -->
        <section id="chat-window" class="chat-window">
            <!-- Messages -->

            <div class="messages-list">
                <template v-for="(message, index) in filteredMessages">
                    <BankIdStatusMessage
                        v-if="message.MessageType.startsWith('bankIdAgentStatus')"
                        :key="message.ID + 'bankIdStatus'"
                        :message="message"
                    />
                    <MessageBlock
                        v-else
                        :key="message.ID + 'message'"
                        :message="message"
                        :nextMessageSender="nextMessageSender(message)"
                        :clientIsOnline="clientIsOnline"
                        :conversationHistory="conversationHistoryArr"
                        :showGptButton="showGptButton(message)"
                        :showBlockTime="shouldShowBlockTime(message, index)"
                        @gptResponse="inheritedTextInput = $event"
                        @linkClicked="handleLinkClick"
                    />
                </template>

                <!-- Unread messages button -->
                <button
                    id="new-messages-btn"
                    :class="unreadClientMessages && 'show-unread-btn'"
                    @click="unreadMsgButtonClick"
                >
                    <v-icon color="white">mdi-arrow-down</v-icon>
                    {{ $t('chat.unreadMessages') }}
                </button>

                <!-- Scroll anchor -->
                <div id="scroll-anchor" :class="stuckToBottom && 'overflow-anchor'"></div>
            </div>

            <!-- Chat is closed-message -->
            <div v-if="chatState === chatStates.CLOSED" class="chat-close-message text-body-1">
                <span>{{ closedByText }}</span>
            </div>
        </section>

        <!-- Chat Input -->
        <ChatInput
            v-if="chatState === chatStates.OPEN && !disabled"
            :socketConnected="socketState === socketStates.CONNECTED"
            :inheritedTextInput="inheritedTextInput"
            :disabled="disabled"
            :comment="item"
            @send="(payload) => chat.emit('sendMessage', payload)"
        />
    </div>
</template>
<script>
    import io from 'socket.io-client';
    import { mapState, mapActions, mapGetters } from 'vuex';
    import cfg from '@/app/config';
    import { getDay } from '@/utils/DateFormatter';
    import { getDateFormat } from '@/utils/Design';
    import { urlRegex, debouncer } from '@/utils/index';

    import InternalType from '@/components/Cases/List/InternalType.vue';

    const socketStates = Object.freeze({
        CONNECTED: 'connected',
        DISCONNECTED: 'disconnected',
        CONNECTING: 'connecting',
        RECONNECTING: 'reconnecting',
    });

    const chatStates = Object.freeze({
        UNKNOWN: 'unknown',
        OPEN: 'open',
        CLOSED: 'closed',
    });

    const allowedMessageTypes = new Set([
        'message',
        'bankIdAgentStatusPending',
        'bankIdAgentStatusSuccess',
        'bankIdAgentStatusFailure',
    ]);

    export default {
        components: {
            MessageBlock: () => import('@/components/Cases/Comments/Chat/MessageBlock.vue'),
            ChatInput: () => import('@/components/Cases/Comments/Chat/ChatInput.vue'),
            BankIdStatusMessage: () => import('@/components/Cases/Comments/Chat/BankIdStatusMessage.vue'),
            InternalType,
        },
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                unreadClientMessages: false,
                initialScrollTop: null, // Add this to track the initial scroll position
                scrollThreshold: 100,
                initializing: true,
                messages: [],
                agentMessage: '',
                chat: null, // The socket object
                clientWritingNow: false,
                timeout: null,
                closedBy: '',
                closeTime: '',
                activeInfo: '',
                bigChat: false,
                filterStyle: { width: '35em', height: '45em' },
                expandIcon: 'mdi-arrow-expand',
                active: 0,
                selectedTemplate: null,
                scrollToBottomDebouncer: null,
                sendMessageLoading: false,
                firstScroll: true,
                inheritedTextInput: '',
                stuckToBottom: false,
                listenersInitialized: false,
                socketState: socketStates.DISCONNECTED,
                chatState: chatStates.UNKNOWN,
                socketStates,
                chatStates,
                verified: false,
                verificationRequested: false,
            };
        },
        computed: {
            ...mapState({
                loggedInUser: (state) => state.Auth.userObject,
                selectedCase: (state) => state.Cases.selectedCase,
                readContent: (state) => state.Layout.readContent,
                readStateContent: (state) => state.Layout.readStateContent,
                clientNowOnCard: (state) => state.Clients.clientNowOnCard,
                emailFrom: (state) => state.Cases.emailFrom,
            }),
            ...mapGetters({
                isReadOnly: 'Cases/isReadOnly',
                isIntegrationActive: 'Integrations.handler/isIntegrationActive',
            }),

            disabled() {
                return this.isReadOnly || this.chatState === chatStates.CLOSED || this.chat?.connected === false;
            },

            conversationHistoryArr() {
                const CONVERSATION_HISTORY_LENGTH = 25;

                return this.messages.slice(-CONVERSATION_HISTORY_LENGTH).map((message) => ({
                    role: 'user',
                    content: [
                        {
                            type: 'text',
                            text: message.Message,
                        },
                    ],
                }));
            },

            clientIsWritingMessageObject() {
                return {
                    ID: 'clientWriting',
                    From: this.item.from,
                    AgentFullName: this.item.from,
                    AgentUserId: 0,
                    Message: '',
                };
            },
            clientIsOnline() {
                const ACTIVE_STATE = 2;
                return this.active >= ACTIVE_STATE;
            },

            chatSize() {
                return {
                    maxWidth: this.bigChat ? '100%' : '600px',
                };
            },
            isVergicChat() {
                return this.selectedCase.case.caseId.includes('vergic');
            },
            closedByText() {
                return `${this.$t('chat.chatClosedBy')} ${this.closedBy} ${this.closeTime}`;
            },

            filteredMessages() {
                return this.messages.filter((message) => allowedMessageTypes.has(message.MessageType));
            },
        },
        watch: {
            chatState(newVal) {
                if (newVal === chatStates.CLOSED && !this.selectedCase.case.caseId) {
                    console.log('Could not find a caseId for this case');
                }
            },
            disableTextArea() {
                const { activeElement } = document;
                const tagNamesToIgnore = new Set(['TEXTAREA', 'INPUT', 'SELECT']);
                if (tagNamesToIgnore.has(activeElement.tagName)) {
                    return;
                }

                if (!activeElement.readOnly && Object.hasOwn(activeElement, 'readOnly')) {
                    return;
                }

                if (Object.hasOwn(activeElement, 'editor')) {
                    return;
                }

                if (this.disableTextArea) return;

                this.focusChatTextField();
            },
            clientWritingNow(newVal) {
                // add or remove pseudo object to messages array to indicate client is writing
                newVal ? this.addClientIsWritingIndicator() : this.removeClientIsWritingIndicator();
            },

            firstScroll(newVal) {
                if (!newVal) {
                    this.scrollToBottomDebouncer(0, true);
                }
            },
        },

        created() {
            this.init();
        },

        beforeDestroy() {
            if (this.chat) {
                this.chat.off(); // Remove listeners
                this.chat.disconnect(); // Disconnect from socket
                this.listenersInitialized = false;
            }
            this.setShowNotification(true);
        },
        beforeUnmount() {
            const messagesContainer = this.$el.querySelector('.messages-list');
            if (!messagesContainer) return;
            messagesContainer.removeEventListener('scroll', this.trackScrollActivity);
        },

        mounted() {
            if (this.isVergicChat) return;

            // event listener to hide unread messages button when scrolling
            const messagesContainer = this.$el.querySelector('.messages-list');

            if (messagesContainer) {
                messagesContainer.addEventListener('scroll', this.trackScrollActivity);

                // IntersectionObserver to check if the user has scrolled to the bottom,
                // and should 'stick' to the bottom
                const observer = new IntersectionObserver(this.handleScrollAnchorIntersection, {
                    root: messagesContainer,
                });

                const scrollAnchor = this.$el.querySelector('#scroll-anchor');
                if (scrollAnchor) {
                    observer.observe(scrollAnchor);
                }
            }
        },

        methods: {
            ...mapActions('Layout', ['setReadChat']),
            ...mapActions('Chat', ['setShowNotification']),
            getDay,
            getDateFormat,
            async init() {
                await this.getChatStatus();

                this.connectToSocket();

                this.setShowNotification(false);
                this.scrollToBottomDebouncer = debouncer(this.scrollToBottom, 100);
            },

            intitiateBankIdVerification() {
                this.chat.emit('makeBankIdPrompt', {
                    agentUserId: this.loggedInUser.userId,
                    rand: this.item.caseId,
                });
                this.verificationRequested = true;
            },

            removeLocalStorageMsg() {
                localStorage.removeItem(`__chatText_${this.$router.currentRoute.path}`);
            },
            resetUnreadMessages() {
                this.unreadClientMessages = false;
                this.initialScrollTop = null; // Reset the initial scroll position after hiding the button
            },
            trackScrollActivity() {
                const container = this.$el.querySelector('.messages-list');
                if (!container) return;

                // Initialize the initial scroll position if it's the first scroll
                if (this.initialScrollTop === null) {
                    this.initialScrollTop = container.scrollTop;
                }

                // Check if the user has scrolled beyond the threshold distance
                const distanceScrolled = Math.abs(container.scrollTop - this.initialScrollTop);

                if (distanceScrolled > this.scrollThreshold) {
                    this.resetUnreadMessages();
                }
            },
            handleScrollAnchorIntersection(entries) {
                for (const entry of entries) {
                    if (entry.isIntersecting) {
                        this.stuckToBottom = true;
                    } else {
                        this.stuckToBottom = false;
                    }
                }
            },
            scrollDistanceFromBottom() {
                const container = this.$el.querySelector('.messages-list');

                if (!container) {
                    return 0;
                }

                const { scrollHeight, scrollTop, clientHeight } = container;
                return scrollHeight - scrollTop - clientHeight;
            },
            unreadMsgButtonClick() {
                this.unreadClientMessages = false;
                this.scrollToBottomDebouncer(0);
            },
            async handleLinkClick(event) {
                const target = event.target.closest('a');

                if (!target || !target.href) return;

                event.preventDefault();
                const { href } = target;

                try {
                    const { confirmed } = await this.$global.dialogs.showConfirmationDialog({
                        title: this.$t('chat.leaveSite'),
                        message: this.$t('chat.continueTo') + href,
                        confirmText: this.$t('global.btn.confirm'),
                        declineText: this.$t('global.btn.cancel'),
                    });

                    if (!confirmed) return;
                    window.open(href, '_blank');
                } catch (error) {
                    console.error('Error showing confirmation dialog:', error);
                }
            },
            showGptButton(message) {
                return message.From !== 'agent' && message.ID !== 'clientWriting' && this.chatState === chatStates.OPEN;
            },
            addClientIsWritingIndicator() {
                this.messages.push(this.clientIsWritingMessageObject);
            },
            removeClientIsWritingIndicator() {
                this.messages = this.messages.filter((msg) => msg.ID !== 'clientWriting');
            },
            nextMessageSender(message) {
                return this.messages[this.messages.indexOf(message) + 1]?.From;
            },
            finalizeMessageReception() {
                this.sendMessageLoading = false;
                this.clearWriting();
            },
            handleUnreadMessages() {
                // if user is not at the bottom, show unread messages button
                if (this.scrollDistanceFromBottom() > 100) {
                    this.unreadClientMessages = true;
                }
            },
            handleNewClientMessage(data) {
                this.clientWritingNow = false;
                this.clearWriting();
                this.messages.push(data);
                this.handleUnreadMessages();
            },
            handleNewAgentMessage(data) {
                if (this.clientWritingNow) {
                    // Insert the agent's message at the second to last position
                    // to keep client writing indicator dots at last place still
                    this.messages.splice(-1, 0, data);
                } else {
                    this.messages.push(data);
                }
            },

            shouldShowBlockTime(message, index) {
                // If it's the first message, show the time
                if (index === 0) {
                    return true;
                }

                const previousMessage = this.messages[index - 1];

                // Calculate the time difference in minutes
                const timeDifferenceInMinutes =
                    (new Date(message.DateTime) - new Date(previousMessage.DateTime)) / 60000;

                // If the time difference is more than 1 minute, show the time
                return timeDifferenceInMinutes > 3;
            },
            focusChatTextField() {
                this.$nextTick(() => {
                    this.$refs.chatMessage.$refs.input.focus();
                });
            },
            generateLinkTag(message) {
                return message.replaceAll(urlRegex, (url) => {
                    if (!url.match('^https?://') && !url.includes('@')) {
                        url = `https://${url}`;
                    }
                    const isValidHttpUrl = this.isValidHttpUrl(url);
                    if (!isValidHttpUrl) {
                        return url;
                    }

                    return `<a target="_blank" rel="noopener noreferrer" href="${url}">${url}</a>`;
                });
            },

            isValidHttpUrl(string) {
                let url = null;
                try {
                    url = new URL(string);
                } catch (_) {
                    return false;
                }
                return url.protocol === 'http:' || url.protocol === 'https:';
            },

            async closeChat() {
                const { confirmed } = await this.$global.dialogs.showConfirmationDialog({
                    title: this.$t('chat.sureClose'),
                    confirmText: this.$t('global.btn.save'),
                    declineText: this.$t('global.btn.cancel'),
                });

                if (!confirmed) return;
                const tempInteractionObject = {
                    visitorId: this.item.clientId,
                    agentFullName: this.loggedInUser.name,
                    agentUserId: this.loggedInUser.userId,
                    rand: this.item.caseId,
                    from: 'agent',
                };
                this.chat.emit('chatClosed', tempInteractionObject);
                this.$toasted.show(this.$t('chat.chatClosed'));
                this.setChatState(chatStates.CLOSED);

                this.$store.commit('Cases/setReloadCaseContent', true);
                this.removeLocalStorageMsg();
            },
            async getChatStatus() {
                const res = await this.$store.dispatch('Chat/getChatStatus', this.item.caseId);

                this.setChatState(res.result.open ? chatStates.OPEN : chatStates.CLOSED);

                this.setReadChat(this.chatState === chatStates.OPEN ? 'READ_WRITE' : 'READ_ONLY');

                this.verified = res.result.verified;

                if (this.chatState === chatStates.OPEN) return;

                this.closedBy = res.result.closedBy[0]?.Name || `${this.$t('chat.client')}.`;

                const terminated = res.result?.closedBy[0]?.Terminated || null;

                if (terminated) this.closeTime = getDay(terminated);
            },
            toggleSize() {
                this.bigChat = !this.bigChat;
                if (this.bigChat) {
                    this.filterStyle = { width: '100%', height: '45em' };
                    this.expandIcon = 'mdi-arrow-collapse';
                } else {
                    this.filterStyle = { width: '35em', height: '45em' };
                    this.expandIcon = 'mdi-arrow-expand';
                }
            },
            setActiveState(active) {
                this.active = active;
                const ACTIVE_STATE = 2;
                if (active >= ACTIVE_STATE) {
                    this.activeInfo = '<span style="font-size:0.9em;">Aktiv</span>';
                } else {
                    this.activeInfo = '<span style="font-size:0.9em;">Offline</span>';
                }
            },

            scrollToBottom(maxDistanceFromBottom = 0, instant = false) {
                const container = this.$el.querySelector('.messages-list');

                if (!container) return;
                const shouldScroll =
                    maxDistanceFromBottom === 0 || this.scrollDistanceFromBottom() <= maxDistanceFromBottom;
                if (!shouldScroll) return;

                if (instant) {
                    container.scrollTop = container.scrollHeight;
                    return;
                }

                container.scrollTo({
                    top: container.scrollHeight,
                    behavior: 'smooth',
                });
            },

            clientWritingAnimation() {
                this.clientWritingNow = true;
                this.clearWriting();
                const WRITING_TIMEOUT = 4000;
                this.timeout = setTimeout(() => {
                    this.clientWritingNow = false;
                }, WRITING_TIMEOUT);
            },
            clearWriting() {
                clearTimeout(this.timeout);
            },
            setChatState(state) {
                this.chatState = state;
            },
            setSocketState(state) {
                this.socketState = state;
            },
            initListenerChat() {
                if (this.chat) {
                    if (this.listenersInitialized) return; // Prevent re-initialization
                    this.listenersInitialized = true;

                    this.chat.on('inititalConversation', (data) => {
                        for (const message of data) {
                            message.Message = this.generateLinkTag(message.Message);
                        }
                        this.messages = data;
                        this.clientWritingNow = false;
                        this.clearWriting();
                        this.firstScroll = false;
                    });
                    this.chat.on('error', (error) => {
                        console.error('Socket error:', error);
                    });

                    // CONNECTION HANDLING
                    this.chat.on('connect', () => {
                        console.log('Connected to chat socket');
                        this.setSocketState(socketStates.CONNECTED);
                        this.getChatStatus();

                        this.emitJoinConversation();
                    });
                    this.chat.on('disconnect', (reason, details) => {
                        console.error('Disconnected from chat socket', { reason, details });
                        // notify user that has been disconnected from socket
                        this.setSocketState(socketStates.DISCONNECTED);

                        this.setReadChat('READ_ONLY');

                        // TODO: Might not want to show toast here if this happens when chat closes?
                        if (this.chatState === chatStates.OPEN) {
                            this.$toasted.error(this.$t('chat.lostConnection'), {
                                icon: 'cancel',
                                type: 'error',
                            });
                        }

                        if (this.chat.active) {
                            // temp disconnection, will automatically reconnect
                            console.warn('Temporary socket disconnection, automatically reconnecting...');
                        } else {
                            // Connection was forcefully closed by server or client itself
                            console.warn(reason);
                        }
                    });
                    this.chat.on('reconnect_attempt', (attempt) => {
                        this.setSocketState(socketStates.RECONNECTING);

                        console.log('Trying to reconnect... Attempt no:', attempt);
                    });
                    this.chat.on('reconnect', () => {
                        // Successfully reconnected
                        this.setSocketState(socketStates.CONNECTED);

                        this.getChatStatus();

                        this.emitJoinConversation();

                        console.log('You have been successfully reconnected to chat');
                        this.$toasted.success(this.$t('chat.reconnected'));
                    });
                    this.chat.on('reconnect_error', () => {
                        console.log('Reconnection attempt failed :(');
                    });
                    this.chat.on('reconnect_failed', () => {
                        console.log('All reconnection attempts failed, you have failed :(');
                        this.$toasted.error('chat.tryRefreshing');
                    });
                    // END OF CONNECTION ISSUES HANDLING
                    this.chat.on('clientWritingMessage', (data) => {
                        /* check length to avoid showing writing indicator dots
                         when client presses enter to send message */
                        data?.length && this.clientWritingAnimation(data);
                    });
                    this.chat.on('totalClientsOnline', (data) => {
                        this.setActiveState(data.totalInChatRoom);
                    });
                    this.chat.on('messageObject', (data) => {
                        this.messages = data.reverse();
                        this.clientWritingNow = false;
                        this.clearWriting();
                    });
                    this.chat.on('chatInQueue', (data) => {
                        if (data.found) {
                            this.$toasted.show(this.$t('chat.caseInQueue'), {
                                icon: 'cancel',
                                type: 'error',
                            });
                            this.$store.commit('Cases/addCaseToCasesInQueue', data.id);
                            return;
                        }
                        this.$store.commit('Cases/removeCaseFromCasesInQueue', data.id);
                    });

                    this.chat.on('incrementMessage', (data) => {
                        data.Message = this.generateLinkTag(data.Message);

                        data.From === 'agent' ? this.handleNewAgentMessage(data) : this.handleNewClientMessage(data);

                        this.finalizeMessageReception();
                    });

                    this.chat.on('updateMessage', (data) => {
                        Object.assign(
                            this.messages.find((msg) => msg.ID === data.ID),
                            data
                        );
                    });

                    this.chat.on('chatIsNowClosed', () => {
                        this.setChatState(chatStates.CLOSED);
                        this.getChatStatus();
                        this.removeLocalStorageMsg();
                        this.chat.disconnect(); // Chat is now closed, kill socket
                    });

                    this.chat.on('bankIdVerified', () => {
                        this.getChatStatus();
                    });
                }
            },
            connectToSocket() {
                if (this.chat) {
                    if (!this.chat.connected && !this.chat.connecting) {
                        this.setSocketState(socketStates.CONNECTING);
                        this.chat.connect();
                    }

                    return;
                }
                this.chat = io(`${cfg.customerFirstUrl}chatmanager`, {
                    transports: ['websocket'],
                    autoConnect: true,
                    upgrade: true,
                    useConnectionNamespace: false,
                    reconnectionAttempts: 10,
                    reconnectionDelay: 1000,
                    reconnectionDelayMax: 5000,
                    pingTimeout: 3000,
                    pingInterval: 2000,
                    path: '/api/chatmanager/socket.io',
                    query: {
                        token: this.$store.state.Auth.accessToken || '',
                    },
                });

                this.initListenerChat();
            },
            emitJoinConversation() {
                this.chat.emit('joinConversation', {
                    visitorId: this.item.clientId,
                    agentUserId: this.loggedInUser.userId,
                    fromAgent: true,
                    rand: this.item.caseId,
                });
            },
        },
    };
</script>

<style scoped lang="scss">
    .chat-close-message {
        display: flex;
        height: 100px;
        justify-content: center;
        align-items: center;
        padding: 8px;
    }

    .comments {
        border-radius: 12px;
        width: 100%;
        gap: 4px;
        font-size: 1rem;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        overflow: hidden;
    }

    header {
        padding: 1.5em;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .actions {
            display: flex;
            gap: 0.5em;
        }
    }

    .chat-window {
        position: relative;
        height: 600px;
        transition: opacity 0.2s ease-in-out;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2em;
        padding: 1.5em;

        #new-messages-btn {
            position: absolute;
            bottom: 1em;
            left: 50%;
            transform: translateX(-50%) translateY(20px);
            justify-content: center;
            align-items: center;
            gap: 1em;
            padding: 0.5em 1em;
            background: var(--v-primary-base);
            border-radius: 4px;
            color: white;
            opacity: 0;
            visibility: hidden;
            transition:
                opacity 0.3s ease-in-out,
                transform 0.3s ease-in-out,
                visibility 0.3s;
        }

        #new-messages-btn.show-unread-btn {
            opacity: 1;
            visibility: visible;
            transform: translateX(-50%) translateY(0);
        }
        .messages-list {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            padding: 0 1em;

            & * {
                overflow-anchor: none;
            }

            #scroll-anchor {
                min-height: 1px;
                width: 100%;
            }

            .overflow-anchor {
                overflow-anchor: auto;
            }
        }
    }

    .vergic-chat {
        background-color: var(--v-gray3-base);
        border-radius: 12px;
        padding: 16px;
        margin-bottom: 16px;
        width: fit-content;
        max-width: 50%;
    }

    .comment-date {
        display: flex;
        align-items: center;
        justify-self: end;
        font-size: 12px;
        color: var(--v-gray2-base);
        white-space: nowrap;
        color: var(--v-gray2-base);
        padding-right: 8px;
        margin-right: 4px;
    }

    .bank-verication-btn {
        --gray: #3f3f3f;
        border: 1.5px solid var(--gray);
        border-radius: 2em;
        padding-right: 0.75em;
        font-weight: 500;
        color: var(--gray);
        text-wrap: nowrap;

        & img {
            height: 2.5em;
            display: inline;
            vertical-align: middle;
        }

        &[disabled] {
            opacity: 0.5;
        }
    }

    .bankid-verified {
        width: 2em;
    }
</style>

<i18n lang="json">
{
    "en": {
        "chat": {
            "unreadMessages": "Unread messages",
            "chatClosed": "The chat is closed",
            "chatClosedBy": "The chat is closed by",
            "sureClose": "Are you sure you want to close the chat?",
            "caseInQueue": "The case is in the queue waiting to be picked up by the automatic assignment",
            "client": "client",
            "continueTo": "You will continue to: ",
            "leaveSite": "Do you want to leave the site?",
            "lostConnection": "You have lost connection to the chat",
            "reconnected": "You have reconnected to the chat",
            "tryRefreshing": "Attempts to connect to chat failed. Try refreshing the page",
            "startBankIdVerification": "Request identification"
        }
    },
    "sv": {
        "chat": {
            "unreadMessages": "Nya meddelanden",
            "chatClosed": "Chatten är nu stängd",
            "chatClosedBy": "Chatten är avslutad av",
            "sureClose": "Är du säker på att du vill stänga chatten?",
            "caseInQueue": "Ärendet är i kön och väntar på att plockas upp av automatisk tilldelning",
            "client": "klient",
            "continueTo": "Du kommer att fortsätta till: ",
            "leaveSite": "Vill du lämna sidan?",
            "lostConnection": "Du har tappat anslutning till chatten",
            "reconnected": "Du har återanslutit till chatten",
            "tryRefreshing": "Försök att återansluta till chatten misslyckades. Vänligen ladda om sidan och försök på nytt.",
            "startBankIdVerification": "Begär identifiering"
        }
    }
}
</i18n>
