// swedishMobileRegex can not have g tag, because it is used in a loop
export const swedishMobileRegex = /^(\+46|0)\s*(7[0236])\s*(\d{4})\s*(\d{3})$/;
export const mailRegEx =
    /^(?:[\w!#$%&'*+/=?^`{|}~-]+(?:\.[\w!#$%&'*+/=?^`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f!\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[\da-z](?:[\da-z-]*[\da-z])?\.)+[\da-z](?:[\da-z-]*[\da-z])?|\[(?:(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d{1,2}|[\da-z-]*[\da-z]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/i;
export const mailRegExMultiple =
    /(?:[\w!#$%&'*+/=?^`{|}~-]+(?:\.[\w!#$%&'*+/=?^`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f!\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[\da-z](?:[\da-z-]*[\da-z])?\.)+[\da-z](?:[\da-z-]*[\da-z])?|\[(?:(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d{1,2}|[\da-z-]*[\da-z]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/gim;

export const swedishNumberRegex =
    /^(\+46[\s-]?\d{2}[\s-]?\d{3}[\s-]?\d{4})$|^(0\d{2}[\s-]?\d{3}[\s-]?\d{4})$|^(\+46\d[\s-]?\d{3}[\s-]?\d{4})$|^(0\d[\s-]?\d{3}[\s-]?\d{4})$|^(0\d[\s-]?\d{2}[\s-]?\d{4})$|^(\+46\d[\s-]?\d{2}[\s-]?\d{4})$/;

export const internationalNumberRegex = /^\+\d{7,}$/;

export const nonGlobalInternationalNumberRegex =
    /\+(9[679]\d|8[0357-9]\d|6[7-9]\d|5[09]\d|42\d|3[578]\d|2[1-689]\d|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[07]|7|1)\d{1,14}$/;

export const internationalOrInternalNumberRegex = /^\+?\d{3,}$/;

export const dateRegex = /(\d{4}-\d{2}-\d{2})$/;
export const dateTimeRegex = /\d{4}-\d{2}-\d{2}\s(0?\d|1\d|2[0-3]):\d+/;

export const urlRegex =
    /((https?:\/\/)?(www\.)?[\w#%+.:=@~-]{2,256}\.[a-z]{2,6}(?:[\w#%&+./:=?@~,-]*)?)(?=\s|$|,|;|\.|\))/gm;
