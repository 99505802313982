<template>
    <main class="internal-type-container" :class="getPositionClass(position)">
        <v-tooltip bottom>
            <template #activator="{ on, attrs }">
                <div v-bind="attrs" :cy-value="item.internalType" v-on="on">
                    <Transition name="fade">
                        <v-badge
                            v-if="
                                getSliderPercentage(item.sla, item.dateTime, returnDateNow) < 100 &&
                                item.agentName == '' &&
                                getSliderPercentage(item.sla, item.dateTime, returnDateNow) != -1 &&
                                item.status != 'Closed'
                            "
                            bordered
                            color="color1"
                            :content="item.unreadMessages"
                            overlap
                            :value="item.unreadMessages > 0"
                            :dot="small"
                        >
                            <v-avatar :color="buttonColor" :size="getAvatarSize()">
                                <v-icon v-if="item.internalType !== 'callback'" :size="getIconSize()" color="white">
                                    {{ caseTypeIcon(item.internalType) }}
                                </v-icon>
                                <v-icon v-else :size="getIconSize()" color="white">
                                    {{ caseTypeIcon(item.internalType) }}
                                </v-icon>
                            </v-avatar>
                        </v-badge>

                        <v-badge v-if="hasParent && showParentBadge" :content="null" bordered color="primary" overlap>
                            <template #badge>
                                <v-icon size="10">mdi-source-branch</v-icon>
                            </template>
                            <v-avatar color="primary" size="24">
                                <v-icon size="12" color="white">mdi-email</v-icon>
                            </v-avatar>
                        </v-badge>

                        <v-badge
                            v-else
                            bordered
                            color="color1"
                            :content="item.unreadMessages"
                            overlap
                            :value="item.unreadMessages > 0"
                            :dot="small"
                        >
                            <v-avatar :color="buttonColor" :size="getAvatarSize()">
                                <v-icon
                                    v-if="item.internalType !== 'callback'"
                                    :size="getIconSize()"
                                    :color="item.unreadMessages > 0 ? 'white' : 'white'"
                                >
                                    {{ caseTypeIcon(item.internalType) }}
                                </v-icon>
                                <v-icon
                                    v-else
                                    :size="getIconSize()"
                                    :color="item.unreadMessages > 0 ? 'white' : 'white'"
                                >
                                    {{ caseTypeIcon(item.internalType) }}
                                </v-icon>
                            </v-avatar>
                        </v-badge>
                    </Transition>
                </div>
            </template>
            <span>{{ tooltipText }}</span>
        </v-tooltip>
    </main>
</template>

<script>
    import { mapState } from 'vuex';
    import { positionTypes } from '@/enums/cases.enums';
    import { getPositionClass } from '@/helpers/cases/cases.helper';
    import { getSliderPercentage } from '@/utils/Design';
    import { filterObjectsByKeys, getActiveFromSettings } from '../../../utils';
    import { channelTypes } from '../../../templates/cases.template';

    export default {
        props: {
            item: {
                type: Object,
                required: true,
            },

            returnDateNow: {
                type: Date,
                required: false,
                default: () => new Date(),
            },

            small: {
                type: Boolean,
                default: false,
            },

            absolute: {
                type: Boolean,
                default: false,
            },
            position: {
                type: String,
                default: positionTypes.LEFT,
                validator(value) {
                    // Shorthand for validator
                    return Object.values(positionTypes).includes(value);
                },
            },
            isInactive: {
                type: Boolean,
                default: false,
            },
            showParentBadge: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            ...mapState({
                channelConfig: (state) => state.System.userSettings.system.channels,
                timeline: (state) => state.Cases.caseTimeline,
            }),

            internalTypeStyle() {
                return { width: '100%' };
            },
            filteredChannelTypes() {
                const channelBooleans = getActiveFromSettings(this.channelConfig);
                return filterObjectsByKeys(channelBooleans, channelTypes, 'value');
            },
            isBig() {
                if (this.small) {
                    return false;
                }
                return this.listLayout.value === 'big';
            },
            listLayout() {
                return this.$store.state.Cases.listLayout;
            },
            buttonColor() {
                if (this.item.internalType === 'social') {
                    return this.$store.state.Social.social[this.item.source]?.color;
                }
                if (this.item.internalType === 'callback') {
                    const obj = this.filteredChannelTypes.find((t) => t.value === 'callback');
                    return obj.color;
                }
                if (this.isInactive) {
                    return 'var(--v-gray2-lighten1)';
                }
                return 'primary';
            },
            badgeIcon() {
                if (
                    this.item.internalSubType === 'message' &&
                    this.item.internalType === 'social' &&
                    this.item.source === 'facebook'
                ) {
                    return this.$store.state.Social.social.facebookmessenger?.icon;
                }
                return this.$store.state.Social.social[this.item.source]?.icon;
            },
            hasParent() {
                return this.item.parent;
            },

            tooltipText() {
                return this.hasParent
                    ? `${this.getType(this.item.internalType)} ${this.$t('internalType.withSubcase')}`
                    : `${this.getType(this.item.internalType)}`;
            },
        },

        methods: {
            getSliderPercentage,
            getPositionClass,
            getAvatarSize() {
                return this.isBig ? 32 : 24;
            },
            getIconSize() {
                return this.isBig ? 16 : 12;
            },
            getType(type) {
                if (!type) {
                    return '';
                }
                if (this.item.internalType === 'social') {
                    return this.$store.state.Social.social[this.item.source]?.name;
                }
                const obj = this.filteredChannelTypes.find((t) => t.value === type);

                if (!obj) {
                    // return 'Okänd';
                    return this.$t('internalType.unknown');
                }
                return obj.nameTranslate[this.$i18n.locale];
            },
            caseTypeIcon(type) {
                if (!type) {
                    return '';
                }

                if (this.item.isChild) {
                    return 'mdi-source-branch';
                }

                if (this.item.internalType === 'social') {
                    return this.badgeIcon;
                }

                if (type === 'email' && this.item.internalCreated === 2) {
                    return 'mdi-email';
                }

                const obj = this.filteredChannelTypes.find((t) => t.value === type)?.icon;
                if (!obj) {
                    return 'mdi-help-circle';
                }
                return obj;
            },
        },
    };
</script>

<style scoped>
    .nohover::before {
        display: none;
    }

    .internal-type-container {
        height: 100%;
        display: flex;
        align-items: center;
    }
</style>
<i18n lang="json">
{
    "en": {
        "internalType": {
            "unknown": "Unknown",
            "withSubcase": " - Subcase"
        }
    },
    "sv": {
        "internalType": {
            "unknown": "Okänd",
            "withSubcase": " - Underärende"
        }
    }
}
</i18n>
